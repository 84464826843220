
import { defineComponent, ref } from "vue";
import FileUploader from "./FileUploader.vue";
import { i18nTranslate } from "@/plugins/i18n";

export default defineComponent({
  props: {
    textInstruction: {
      type: String,
      default: i18nTranslate("Upload your avatar"),
    },
    avatar: {
      type: String,
    },
  },
  emits: ["change", "image-change"],
  setup(props, { emit }) {
    const uploaderRef = ref();
    const currentAvatarUrl = ref();

    return {
      uploaderRef,
      currentAvatarUrl,
      onChange: (url) => {
        currentAvatarUrl.value = url;

        emit("change", url);
      },
    };
  },
  components: { FileUploader },
});
