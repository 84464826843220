/**
 * This will calculate resize width and height based on max values
 *
 * @example
 * calculateAspectRatioFit(500, 1000, 400, 400);
 * => {width: 200, height: 400}
 */
export const calculateAspectRatioFit = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

/**
 * Get pixel number value from em/rem
 */
export const remToPixelNumber = (remValue: string, rem = 16) => {
  // get number value using regex
  const numValue = Number(remValue.replace(/\D+/g, ""));

  if (remValue.endsWith("px")) {
    // if already in pixels, just return the number value
    return numValue;
  } else if (!remValue.endsWith("em")) {
    console.log("Make sure value is in em or rem format", { remValue, rem });
    return numValue;
  }
  return numValue * rem;
};
