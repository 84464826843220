import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cropper = _resolveComponent("cropper")!
  const _component_a_modal = _resolveComponent("a-modal")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_upload_dragger = _resolveComponent("a-upload-dragger")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      visible: _ctx.cropVisible,
      "ok-button-props": { class: 'm-0 order-1' },
      "cancel-button-props": { class: 'order-2' },
      width: _ctx.modalWidth,
      height: "32rem",
      style: {"top":"1rem"},
      "wrap-class-name": "file-uploader-cropper-modal",
      "mask-closable": false,
      closable: false,
      onOk: _ctx.onCrop,
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.editing = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_cropper, {
          ref: "cropperRef",
          class: "cropper",
          src: _ctx.imageToCrop,
          "stencil-props": {
        aspectRatio: 1 / 1,
      },
          "background-class": "cropper-background",
          "area-class": "cropper-area",
          "default-size": _ctx.defaultSize,
          "min-width": _ctx.minCropWidth,
          "min-height": _ctx.minCropHeight
        }, null, 8, ["src", "default-size", "min-width", "min-height"])
      ]),
      _: 1
    }, 8, ["visible", "width", "onOk"]),
    _createVNode(_component_a_upload_dragger, {
      fileList: _ctx.fileList,
      "onUpdate:fileList": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fileList) = $event)),
      name: "file",
      multiple: _ctx.multiple,
      "show-upload-list": _ctx.showUploadList,
      class: "file-uploader",
      "before-upload": _ctx.beforeUpload,
      accept: _ctx.acceptedFileTypes,
      onChange: _ctx.handleChange
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.image ? _ctx.FileUploadWhiteIcon : _ctx.FileUploadIcon,
          class: "mb-2"
        }, null, 8, _hoisted_1),
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.image && 'file-uploader__labels'])
        }, [
          (_ctx.textTitle)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 0,
                class: "font-semibold"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.textTitle), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.textInstruction)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 1,
                class: "font-normal"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.textInstruction), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.textSupported)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 2,
                class: "ant-upload-hint lighter"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.textSupported), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.textSupportedSublabel)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 3,
                class: "ant-upload-hint lighter"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.textSupportedSublabel), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["fileList", "multiple", "show-upload-list", "before-upload", "accept", "onChange"])
  ], 64))
}