import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "avatar-uploader" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUploader = _resolveComponent("FileUploader")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FileUploader, {
      textTitle: "",
      textInstruction: _ctx.textInstruction,
      ref: "uploaderRef",
      onChange: _ctx.onChange,
      onFileChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('image-change', $event))),
      canCrop: "",
      minImageHeight: 400,
      minImageWidth: 400,
      avatarUpload: "",
      avatar: _ctx.avatar
    }, null, 8, ["textInstruction", "onChange", "avatar"])
  ]))
}