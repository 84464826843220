
import { computed, defineComponent, ref } from "vue";
import { message } from "ant-design-vue";
import FileUploadIcon from "@/assets/file-upload.svg";
import FileUploadWhiteIcon from "@/assets/file-upload-white.svg";
import {
  calculateAspectRatioFit as remToPx,
  remToPixelNumber,
} from "@/shared/utils/styling";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { i18nTranslate } from "@/plugins/i18n";
import lessVariable from "@/less/variables.less";
import { useI18n } from "vue-i18n";

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
  size: number;
  type?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

export const acceptedFileTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp",
];

const defaultModalWidth = "32rem";

const __default__ = defineComponent({
  components: { Cropper },
  props: {
    acceptedFileTypes: {
      type: String,
      default: acceptedFileTypes.join(","),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    showUploadList: {
      type: Boolean,
      default: false,
    },
    canCrop: {
      type: Boolean,
      default: false,
    },
    textTitle: {
      type: String,
      default: i18nTranslate("Upload new file"),
    },
    textInstruction: {
      type: String,
      default: i18nTranslate("Upload your avatar"),
    },
    textSupported: {
      type: String,
      default: "jpg, png, gif",
    },
    textSupportedSublabel: {
      type: String,
      default: "at least 400x400px, less than 5MB",
    },
    maxSizeMB: {
      type: Number,
      default: 5,
    },
    minCropWidth: {
      type: Number,
      default: 400,
    },
    minCropHeight: {
      type: Number,
      default: 400,
    },
    minImageWidth: {
      type: Number,
      default: 0,
    },
    minImageHeight: {
      type: Number,
      default: 0,
    },
    // check if parent component is an avatar uploader
    avatarUpload: {
      type: Boolean,
      default: false,
    },
    avatar: {
      type: String,
    },
  },
  emits: ["change", "file-change"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const image = ref(props.avatar);
    const imageToCrop = ref();
    const modalWidth = ref(defaultModalWidth);

    const editing = ref(false);
    const fileList = ref<FileItem[]>([]);

    const cropperRef = ref<InstanceType<typeof Cropper>>();

    const isFileValid = (file: FileItem, showError = false) => {
      if (file) {
        /**
         * Check if file size is not more than allowed, this is set to 5MB by default
         */
        const isSizeWithInAllowed = file.size / 1024 / 1024 <= props.maxSizeMB;
        if (!isSizeWithInAllowed) {
          if (showError) {
            message.error(
              t(`Image must be smaller than ${props.maxSizeMB}MB!`)
            );
          }

          return false;
        }
      }

      return true;
    };

    const handleChange = (info: FileInfo) => {
      if (!isFileValid(info.file)) {
        return;
      }
      // TODO: upload API here, validate image first
      console.log("handleChange", info);
    };

    const beforeUpload = (file: FileItem) => {
      if (!isFileValid(file, true)) {
        return;
      }
      // Check file type if Image if component is from AvatarUpload
      if (!file.type?.includes("image") && props.avatarUpload) {
        message.error(t("Please make sure the uploaded file is an image"));
        return;
      }

      const newImageUrl = URL.createObjectURL(file);
      const imageObj = new Image();
      imageObj.src = newImageUrl;

      imageObj.onload = () => {
        let imageLoadValid = true;
        const imageWidth = imageObj.width;
        const imageHeight = imageObj.height;

        // 96 = footer + top and bottom padding
        const usableWindowHeight = window.innerHeight - 96;
        const computedScaledImage = remToPx(
          imageWidth,
          imageHeight,
          remToPixelNumber(defaultModalWidth), // get defaultModalWidth pixel value for max width
          usableWindowHeight
        );

        /**
         * Adjust modal width based on the image value computation
         */
        if (imageHeight > computedScaledImage.height) {
          modalWidth.value = computedScaledImage.width + "px"; // this won't matter if it's in rem
        } else {
          modalWidth.value = defaultModalWidth; // always reset to default value
        }

        /**
         * Check image dimension
         */
        if (
          imageWidth < props.minImageWidth ||
          imageHeight < props.minImageHeight
        ) {
          message.error(
            t(
              `The minimum image size is ${props.minImageWidth}x${props.minImageHeight} pixels.`
            )
          );
          imageLoadValid = false;
        }
        /**
         * Set to reference if valid width or height
         */
        if (imageLoadValid) {
          // Revoke the object URL, to allow the garbage collector to destroy the uploaded old file
          // Only revoke, if new file is a valid file
          if (imageToCrop?.value) {
            URL.revokeObjectURL(imageToCrop.value);
          }
          fileList.value = [file];
          imageToCrop.value = newImageUrl;
          editing.value = true; // open cropper after loading new image to crop
        }
      };

      return false;
    };

    const onCrop = () => {
      if (image?.value) {
        URL.revokeObjectURL(image.value);
      }

      const result = cropperRef.value?.getResult();
      const fileName = (() => {
        if (!fileList.value[0].name) {
          // default name if not available
          return "image.jpeg";
        }
        // remove any file extension and use jpeg
        return fileList.value[0].name?.replace(/\.[^/.]+$/, ".jpeg");
      })();

      result?.canvas?.toBlob(
        (blob) => {
          if (blob) {
            const newImageUrl = URL.createObjectURL(blob);
            image.value = newImageUrl;
            emit("change", newImageUrl);

            /**
             * Emit file change
             * create new File image using new File
             */
            const blobFile = new File([blob], fileName, { type: blob.type });
            emit("file-change", blobFile);
          }
        },
        "image/jpeg",
        0.95 // JPEG at 95% quality
      );

      editing.value = false;
      return result;
    };

    return {
      t,
      modalWidth,
      cropperRef,
      image,
      imageToCrop,
      editing,
      handleChange,
      fileList,
      beforeUpload,
      FileUploadIcon,
      FileUploadWhiteIcon,
      onCrop,
      cropVisible: computed(() => editing.value && props.canCrop),
      defaultSize({ imageSize, visibleArea }) {
        /**
         * Set image size as default visible size
         */
        return {
          width: (visibleArea || imageSize).width,
          height: (visibleArea || imageSize).height,
        };
      },
      // Fetch background for File Uploader
      backgroundValue: computed(() =>
        image.value
          ? // Linear gradient ,see Figma UI
            `${lessVariable.avatarBackgroundColor}, url(${image.value})`
          : lessVariable.cardBackgroundColor
      ),
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "31a99b50": (_ctx.backgroundValue)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__